import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from "react-naver-maps";
import React from "react";
import ShopDialog from "./ShopDialog.js";

import shop_list from "../data/shop_list.json";

function Map({ navermaps }) {
  const [shopList, setShopList] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (shop_list) {
      setShopList(shop_list);
    }
  }, []);

  console.log(shop_list);

  const onClickItem = React.useCallback(
    (index) => {
      console.log(index);
      const item = shopList[index];
      if (item) {
        console.log(item);
        setSelectedItem(item);
        setOpen(true);
      }
    },
    [shopList]
  );

  return (
    <>
      <MapDiv
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <NaverMap
          defaultCenter={
            new navermaps.LatLng(37.207901450248215, 127.03790523461694)
          }
          defaultZoom={17}
          zoomControl
          zoomControlOptions={{
            position: navermaps.Position.RIGHT_BOTTOM,
            style: navermaps.ZoomControlStyle.SMALL,
          }}
          mapDataControl={false}
          logoControl={false}
          scaleControl={false}
        >
          {navermaps &&
            shopList &&
            shopList.map((item, index) => {
              return (
                <Marker
                  key={`marker_${index}`}
                  title={item.name}
                  position={
                    new navermaps.LatLng(item.address.lat, item.address.lng)
                  }
                  onClick={() => {
                    onClickItem(index);
                  }}
                  icon={`${process.env.PUBLIC_URL}/assets/marker.png`}
                  // icon={{
                  //   content: `<div
                  //     style="background-color:#40070B;
                  //           margin: 0px; padding: 0px;
                  //           padding: 5px 10px 5px 10px;
                  //           border-radius: 35px;"
                  //           position: absolute; >
                  //           <img src="${process.env.PUBLIC_URL}/assets/marker.png"
                  //             alt=""
                  //             style="border: 0px solid transparent;"
                  //             display: block;
                  //             max-width: none;
                  //             max-height: none;
                  //             -webkit-user-select: none;
                  //             width: 22px;
                  //             height: 35px;
                  //             left: 0px; top: 0px;">
                  //           ${item.name}
                  //   </div>`,
                  //   size: new navermaps.Size(22, 35),
                  //   anchor: new navermaps.Point(11, 35),
                  // }}
                />
              );
            })}
          {/* animation: naver.maps.Animation;
    position: naver.maps.Coord | naver.maps.CoordLiteral;
    icon: string | naver.maps.ImageIcon | naver.maps.SymbolIcon | naver.maps.HtmlIcon;
    shape: naver.maps.MarkerShape;
    title: string;
    cursor: string;
    clickable: boolean;
    draggable: boolean;
    visible: boolean;
    zIndex: number; */}
        </NaverMap>
      </MapDiv>
      <ShopDialog item={selectedItem} open={open} setOpen={setOpen} />
    </>
  );
}

export default Map;
