import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React from "react";
import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from "react-naver-maps";
import MenuIcon from "@mui/icons-material/Menu";
import RestaurantIcon from "@mui/icons-material/Restaurant";

import MapIcon from "@mui/icons-material/Map";
import TourIcon from "@mui/icons-material/Tour";
import PropTypes from "prop-types";
import Map from "../components/Map";
import Guide from "../components/Guide";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Home() {
  const navermaps = useNavermaps();
  const [menuIndex, setMenuIndex] = React.useState(0);

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <img
            alt="main_title"
            src={`${process.env.PUBLIC_URL}/assets/title.png`}
            width={"auto"}
            height={"44px"}
            style={{ marginRight: "auto", marginLeft: "auto" }}
          />
        </Toolbar>
      </AppBar>

      <Box>
        <CustomTabPanel value={menuIndex} index={0} sx={{ border: 0 }}>
          <Container
            maxWidth="md"
            disableGutters
            sx={{ border: 0, boxShadow: 0 }}
          >
            <Guide />
          </Container>
        </CustomTabPanel>

        <CustomTabPanel value={menuIndex} index={1}>
          <Box
            width={"100%"}
            height={{
              xs: `calc(100vh - ${56 + 56}px)`,
              sm: `calc(100vh - ${64 + 56}px)`,
            }}
          >
            {navermaps && <Map navermaps={navermaps} />}
          </Box>
        </CustomTabPanel>
      </Box>

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <Container
          disableGutters
          sx={{ border: 0, boxShadow: 0, color: "white" }}
        >
          <BottomNavigation
            showLabels
            width="100%"
            value={menuIndex}
            onChange={(event, newValue) => {
              setMenuIndex(newValue);
            }}
          >
            <BottomNavigationAction label="맛슐랭 가이드" icon={<TourIcon />} />
            <BottomNavigationAction label="지도" icon={<MapIcon />} />
          </BottomNavigation>
        </Container>
      </Paper>
    </>
  );
}

export default Home;
