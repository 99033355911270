import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { koKR } from "@mui/material/locale";

// A custom theme for this app
const theme = createTheme(
  {
    palette: {
      primary: {
        // main: "#0587f7",
        main: "#40070B",
      },
      secondary: {
        // main: "#044a87",
        main: "#1a0304",
      },
      error: {
        main: red.A400,
      },
    },
    components: {
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            maxWidth: "100%",
          },
        },
      },
    },
  },
  koKR
);

export default theme;
