import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ShopDialog({ item, open, setOpen }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
  };
  const handleClose = () => {
    setValue(0);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="scroll-dialog-title"
          fontWeight={600}
          component={"div"}
          sx={{ p: 0 }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab
              label="매장 정보"
              {...a11yProps(0)}
              sx={{ height: 60, fontSize: "1.1rem", fontWeight: 600 }}
            />
            <Tab
              label="메뉴"
              {...a11yProps(1)}
              sx={{ height: 60, fontSize: "1.1rem", fontWeight: 600 }}
            />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers>
          <CustomTabPanel value={value} index={0}>
            {item && (
              <>
                {/* <Box
                sx={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/shop/${item.main_image}')`,
                  backgroundRepeatL: "no-repeat",
                  backgroundSize: "cover",
                }}
                height={120}
                width={100}
              ></Box> */}
                {/* <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/shop/${item.main_image}`}
                  with="156px"
                  height={"156px"}
                />
              </Stack> */}
                <Grid
                  container
                  spacing={2}
                  //   mt={2}
                >
                  {/* <Grid item>
                  <Box sx={{ width: 128, height: 128 }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/shop/${item.main_image}`}
                      with="128px"
                      height={"128px"}
                    />
                  </Box>
                </Grid> */}
                  <Grid item xs={12} sm container direction="column">
                    {item.name && (
                      <Box>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          매장명
                        </Typography>
                        <Typography fontSize={"0.94rem"}>
                          {item.name}
                        </Typography>
                      </Box>
                    )}

                    {item.representative_food && (
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          대표 메뉴
                        </Typography>
                        <Typography fontSize={"0.94rem"}>
                          {item.representative_food}
                        </Typography>
                      </Box>
                    )}

                    {item.business_hours && (
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          영업 시간
                        </Typography>
                        <Typography fontSize={"0.94rem"}>
                          {item.business_hours}
                        </Typography>
                      </Box>
                    )}

                    {item.break_time && (
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          휴식 시간
                        </Typography>
                        <Typography fontSize={"0.94rem"}>
                          {item.break_time}
                        </Typography>
                      </Box>
                    )}

                    {item.address && item.address.full_address && (
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          주소
                        </Typography>
                        <Typography fontSize={"0.94rem"}>
                          {item.address.full_address}
                        </Typography>
                      </Box>
                    )}

                    {item.tel && (
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          전화번호
                        </Typography>
                        <Typography fontSize={"0.94rem"}>{item.tel}</Typography>
                      </Box>
                    )}

                    {item.youtube_link && (
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          영상 링크
                        </Typography>

                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          {item.youtube_link_qr_image && (
                            <>
                              <img
                                alt={item.youtube_link_qr_image}
                                src={`${process.env.PUBLIC_URL}/assets/shop/${item.youtube_link_qr_image}`}
                                width={"100px"}
                                height={"100px"}
                              />
                            </>
                          )}
                          <a href={`${item.youtube_link}`}>
                            <Typography fontSize={"0.94rem"}>
                              {item.youtube_link}
                            </Typography>
                          </a>
                        </Stack>
                      </Box>
                    )}

                    {item.evaluation && item.evaluation.evaluator && (
                      // item.evaluation.evaluation_score > 0 &&
                      <Box mt={0.5}>
                        <Typography fontWeight={600} fontSize={"1.0rem"}>
                          맛슐랭 평가점수(1~10)
                        </Typography>
                        <Typography fontSize={"0.94rem"}>
                          {item.evaluation.evaluator} -{" "}
                          {item.evaluation.evaluation_score}점
                        </Typography>
                        <Typography fontSize={"0.94rem"}></Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {console.log(item ? item.arMenu : "")}
            <List>
              {item &&
                item.arMenu &&
                item.arMenu.map((menuItem, index) => {
                  return (
                    <React.Fragment key={`menu_${index}`}>
                      <ListItem disableGutters>
                        <Stack
                          width={100}
                          height={100}
                          sx={{
                            borderRadius: "10px",
                            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/shop/${menuItem.image})`,
                            textAlign: "center",
                            // backgroundPosition: "center center",
                            // backgorundSize: "100% 100%",
                            backgroundSize: "cover",
                            backgroundPosition: "50% 50%",
                            border: "1px solid #eeeeee",
                          }}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {menuItem.image == null ||
                            (menuItem.image.length <= 0 && (
                              <Typography fontSize={"0.8rem"}>
                                사진을
                                <br />
                                준비중
                                <br />
                                입니다
                              </Typography>
                            ))}
                        </Stack>
                        <Stack ml={2}>
                          <Typography fontWeight={500} fontSize={"1rem"}>
                            {menuItem.name}
                          </Typography>
                          <Typography fontWeight={600} fontSize={"1.1rem"}>
                            {menuItem.price
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            원
                          </Typography>
                        </Stack>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
            </List>
          </CustomTabPanel>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ShopDialog;
