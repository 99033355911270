import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Container } from "react-naver-maps";
import shop_list from "../data/shop_list.json";
import CircleIcon from "@mui/icons-material/Circle";
import ShopDialog from "./ShopDialog";

function Guide() {
  const [shopList, setShopList] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (shop_list) {
      // setShopList(shop_list.sort(() => Math.random() - 0.5));
      setShopList(shop_list);
    }
  }, []);

  const onClickItem = React.useCallback(
    (index) => {
      console.log(index);
      const item = shopList[index];
      if (item) {
        console.log(item);
        setSelectedItem(item);
        setOpen(true);
      }
    },
    [shopList]
  );
  return (
    <>
      <img
        alt="map_image"
        src={`${process.env.PUBLIC_URL}/assets/map_image.jpg`}
        width={"100%"}
        height={"auto"}
        style={{ maxHeight: `calc(100vh - ${64 + 56}px)` }}
      />

      <Divider />
      <List
        sx={{ width: "100%", bgcolor: "background.paper", pb: 4 }}
        disablePadding
      >
        {shop_list &&
          shop_list.map((item, index) => {
            return (
              <React.Fragment key={`guide_${index}`}>
                <ListItemButton
                  sx={{ py: 1.2 }}
                  onClick={() => {
                    onClickItem(index);
                  }}
                >
                  <CircleIcon fontSize="small" sx={{ color: "#40070B" }} />
                  <Typography ml={1} fontSize={"1.1rem"} fontWeight={500}>
                    {item.name}
                  </Typography>
                </ListItemButton>
                <Divider />
              </React.Fragment>
            );
          })}
      </List>
      <Stack direction="row" justifyContent="center" alignItems="center" pb={5}>
        <img
          alt="company_logo"
          src={`${process.env.PUBLIC_URL}/assets/company_logo.png`}
          width={"100%"}
          height={"auto"}
          style={{ maxHeight: `68.52px`, maxWidth: `334px` }}
        />
      </Stack>
      <Box height="56px"></Box>
      <ShopDialog item={selectedItem} open={open} setOpen={setOpen} />
    </>
  );
}

export default Guide;
