import * as React from "react";
import Container from "@mui/material/Container";
import Router from "./routes/Router";
import { Paper } from "@mui/material";

export default function App() {
  return (
    <Container
      // maxWidth="sm"
      maxWidth="100%"
      disableGutters
      sx={{ border: 0, boxShadow: 0 }}
    >
      {/* <Paper sx={{ borderRadius: 0 }}> */}
      <Router></Router>
      {/* </Paper> */}
    </Container>
  );
}
